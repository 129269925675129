import React from 'react';
import './atuacao.css';
import { Especialidade } from '../../components';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { espec1, espec2, espec3, espec4 } from './imports';
import { titulo1, titulo2, titulo3, titulo4 } from './titulos';
import { alt1, alt2, alt3, alt4 } from './alts.js';

const Atuacao = () => {
  return (
    <div className="site__atuacao section__padding" id="especialidades">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <h2>Especialidades</h2>
      </AnimationOnScroll>


      <div className="site__atuacao-itens">
        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
          <Especialidade image={espec1} titulo={titulo1} alt={alt1} />
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
          <Especialidade image={espec2} titulo={titulo2} alt={alt2} />
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
          <Especialidade image={espec3} titulo={titulo3} alt={alt3} />
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
          <Especialidade image={espec4} titulo={titulo4} alt={alt4} />
        </AnimationOnScroll>
      </div>

    </div>
  )
}

export default Atuacao;
