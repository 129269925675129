import React from 'react';

import { CookieAlert, FloatingWhats, Frase, InfoAdicional, Navbar, Quando } from './components';
import { Atuacao, Capa, Contato, Duvidas, Rodape, Sobre } from './containers';
import './App.css';
import './fonts.css';
import './animations.css';

// Nome do site no buscador:
// www.analidiarosa.com.br
 
const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Capa />
      <FloatingWhats />
      <Sobre />
      <Atuacao />
      <Quando />
      <Frase />
      <Duvidas />
      <Contato />
      <InfoAdicional />
      <Rodape />
      <CookieAlert />
    </div>
  )
}

export default App;
