import React from 'react';
import './sobre.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ZoomInPic } from '../../components';

import sobreImg from '../../assets/sobre-img.webp';

const Sobre = () => {
  return (
    <div className="site__sobre section__padding" id="sobre">
      <div className="site__sobre-content">
        <div className="site__sobre-content_img">
          <AnimationOnScroll animateIn="slide-in-left" duration={1} animateOnce="true">
            {/* <img src={sobreImg} alt="Foto da psicóloga Ana Lídia Rosa." /> */}
            <ZoomInPic image={sobreImg} alt={"Foto da psicóloga Ana Lídia Rosa."} />
          </AnimationOnScroll>
        </div>
        <div className="site__sobre-content-text">
          <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
            <h2>Sobre a psicóloga:</h2>

            <p>Ana Lídia Rosa é <b>psicóloga clínica</b> (CRP 06/198244) e <b>pós-graduanda</b> em Psicoterapia Psicanalítica pela Universidade Presbiteriana Mackenzie (UPM). Além disso, é <b>pesquisadora acadêmica</b> pelo Laboratório de Política, Comportamento e Mídia (PUC-SP).</p>

            <p>Sua abordagem terapêutica é pautada na <b>Psicanálise</b>, com o objetivo de compreender profundamente o que causa sofrimento ao paciente e oferecer auxílio para lidar com suas questões emocionais de maneira significativa.</p>

            <p>Através do <b>diálogo</b> e das <b>técnicas terapêuticas</b>, você poderá ter mais clareza sobre si e encontrar formas mais saudáveis para lidar com seus sofrimentos e angústias.</p>

            <p>Aqui você encontrará: <b>escuta</b>, <b>acolhimento</b>, <b>sigilo</b>, <b>apoio</b> e <b>união</b>.</p>

            <p>A psicoterapia é um <b>investimento em si mesmo</b>, no seu <b>bem-estar emocional</b> e no seu <b>desenvolvimento pessoal</b>. É uma jornada colaborativa, onde terapeuta e paciente trabalham juntos para promover mudanças positivas e alcançar os objetivos estabelecidos.</p>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  )
}

export default Sobre;
