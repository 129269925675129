import React from 'react';
import './especialidade.css';
import { RoundPic, BotaoWhats } from '../../components';

const Especialidade = ({ image, titulo, alt }) => {
  return (
    <div className="site__especialidade">
      <div className="site__especialidade-titulo">
        <h3>{titulo}</h3>
      </div>

      <div className="site__especialidade-img">
        <RoundPic image={image} alt={alt} />
      </div>

      <div className="site__especialidade-btn">
        <BotaoWhats message={"agende sua consulta"} />
      </div>
    </div>
  )
}

export default Especialidade;
