import React, { useState } from 'react';
import './zoomInPic.css';

const ZoomInPic = ({ image, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  return (
    <div
      className={`zoom-in-pic ${isZoomed ? 'zoomed' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={image} alt={alt} />
    </div>
  );
};

export default ZoomInPic;
