import React, { useState } from 'react';
import './zoomInEspec.css';

const ZoomInEspec = ({ image, alt }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };

    return (
        <div
            className={`zoom-in-espec ${isZoomed ? 'zoomed' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img src={image} alt={alt} />
        </div>
    );
};

export default ZoomInEspec;
