import React from 'react';
import './menu.css';

const Menu = () => (
  <div className="site__menu">
    <>
      <p><a href="#home">Home</a></p>
      <p><a href="#sobre">Sobre</a></p>
      <p><a href="#especialidades">Especialidades</a></p>
      <p><a href="#informacoes">Informações</a></p>
      <p><a href="#contato">Contato</a></p>
    </>
  </div>
);

export default Menu;
