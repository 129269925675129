export const tituloDuvida1 = "A psicoterapia é presencial ou on-line?";

export const tituloDuvida2 = "Quais são os horários de atendimento?";

export const tituloDuvida3 = "Qual é a frequência das sessões?";

export const tituloDuvida4 = "Qual é a duração de cada sessão?";

export const tituloDuvida5 = "Quais são as formas de pagamento?";

export const tituloDuvida6 = "É possível remarcar a sessão?";

export const tituloDuvida7 = "Como posso entrar em contato com você?";
