import React, { useState } from 'react';
import { BotaoWhats, Duvida } from '../../components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './duvidas.css';

import { tituloDuvida1, tituloDuvida2, tituloDuvida3, tituloDuvida4, tituloDuvida5, tituloDuvida6, tituloDuvida7 } from './duvidas-titulos';
import { textoDuvida1, textoDuvida2, textoDuvida3, textoDuvida4, textoDuvida5, textoDuvida6, textoDuvida7 } from './duvidas-textos';

const Duvidas = () => {
  const [expandedInfoIndex, setExpandedInfoIndex] = useState(null);
  const titulos = [tituloDuvida1, tituloDuvida2, tituloDuvida3, tituloDuvida4, tituloDuvida5, tituloDuvida6, tituloDuvida7];
  const textos = [textoDuvida1, textoDuvida2, textoDuvida3, textoDuvida4, textoDuvida5, textoDuvida6, textoDuvida7];

  const handleInfoClick = (index) => {
    if (index === expandedInfoIndex) {
      setExpandedInfoIndex(null);
    } else {
      setExpandedInfoIndex(index);
    }
  };

  return (
    <div className="site__duvidas section__padding" id="informacoes">
      <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
        <h2>Dúvidas Frequentes</h2>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="slide-in-left" duration={1} animateOnce="true">
        <div className="site__duvidas-container">
          {titulos.map((titulo, index) =>
            <Duvida
              key={index}
              titulo={titulo}
              texto={textos[index]}
              expanded={index === expandedInfoIndex}
              isFirstItem={index === 0}
              onClick={() => handleInfoClick(index)}
            />
          )}
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="fade-in-bottom" duration={1} animateOnce="true">
        <div className="site__duvidas-btn">
          <BotaoWhats message={"agende sua consulta"} />
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Duvidas;
