import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { BotaoWhats } from '../../components';
// import imgCapa from '../../assets/img-capa.jpg';
import './capa.css';

const Capa = () => {
  return (
    <div className="site__capa section__padding" id="home">
      <div className="site__capa-content">
        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
          <h1>Ana Lídia Rosa</h1>
          <div className="site__capa-content_descricao">
            <p className='site__capa-content_descricao-broken'>Psicóloga especialista em relacionamentos amorosos, <br />sexualidade e maternidade.</p>
            
            <p className='site__capa-content_descricao-full'>Psicóloga especialista em relacionamentos amorosos, sexualidade e maternidade.</p>
          </div>
          <p>CRP: 06/198244</p>
          <div className="site__capa-content_btn">
            <BotaoWhats message={"agende sua consulta"} />
          </div>
        </AnimationOnScroll>
      </div>

      {/* <div className="site__capa-image">
        <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={0.5} offset={0}>
          <img src={imgCapa} alt="Imagem da capa" />
        </AnimationOnScroll>
      </div> */}
    </div>
  )
}

export default Capa;
