import React, { useState, useEffect } from 'react';
import { Logo, Menu, MenuLateral } from '../../components';
import './navbar.css';

const Navbar = () => {
  const [applyShadow, setApplyShadow] = useState(false);
  const [removeShadow, setRemoveShadow] = useState(false);

  useEffect(() => {
    const handleScrollBlur = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = 0;

      if (scrollPosition > headerHeight && !applyShadow) {
        setApplyShadow(true);
        setRemoveShadow(false);
      } else if (scrollPosition <= headerHeight && applyShadow) {
        setApplyShadow(false);
        setRemoveShadow(true);
      }
    };

    window.addEventListener('scroll', handleScrollBlur);
    return () => {
      window.removeEventListener('scroll', handleScrollBlur);
    };
  }, [applyShadow, removeShadow]);

  return (
    <div className={`site__navbar ${applyShadow ? 'navbar-shadow' : ''} ${removeShadow ? 'navbar-no-shadow' : ''}`}>
      <div className="site__navbar-links">
        <div className="site__navbar-links_logo">
          <Logo />
        </div>
        <div className="site__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="site__navbar-menu">
        <MenuLateral />
      </div>
    </div>
  )
}

export default Navbar;
