export const textoDuvida1 = "A psicoterapia é feita somente de forma on-line pelas plataformas do Google Meet, Zoom ou ligação de vídeo por WhatsApp. ";

export const textoDuvida2 = (
    <span>
      De segunda a sexta, das 8h00 às 20h00.<br /><br />
      Aos sábados, das 8h00 às 12h00.
    </span>
  );
  
  export const textoDuvida3 = (
    <span>
      No início do tratamento, as sessões ocorrem uma vez por semana. A frequência das sessões semanais pode aumentar de acordo com a demanda trazida por cada paciente e sua disponibilidade.<br /><br />
      Durante o processo, os encontros podem ser menos frequentes devido à evolução no tratamento ou às dificuldades de conciliação, mas sempre dependendo da avaliação de cada caso.
    </span>
  );

export const textoDuvida4 = "Cada sessão tem duração de 50 minutos.";

export const textoDuvida5 = "Os pagamentos são feitos por transferência via PIX.";

export const textoDuvida6 = "A consulta poderá ser remarcada através do WhatsApp, desde que o reagendamento seja realizado em até 24h antes do horário da sessão. Você poderá escolher um novo horário de acordo com a agenda disponível.";

export const textoDuvida7 = "Basta preencher o formulário ou entrar em contato comigo pelo WhatsApp.";
