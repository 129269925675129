import React from 'react';
import './quando.css';

import { BotaoWhats, Logo } from '../../components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
// import logo from '../../assets/logo.png';

const Quando = () => {
  return (
    <div className="site__quando section__padding">
      <AnimationOnScroll animateIn="slide-in-left" duration={1} animateOnce="true">
        <div className="site__quando-header">
          <div className="site__quando-header_titulo">
            <Logo />
            <h2>Quando procurar um psicólogo?</h2>
          </div>

          <div className="site__quando-header_btn">
            <BotaoWhats message={"agende sua consulta"} />
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
        <div className="site__quando-content">
          <p>Você deve procurar um psicólogo se:</p>

          <p>1) Você deseja <b>entender seus sentimentos</b> de forma mais profunda;</p>

          <p>2) Você precisa se <b>comunicar melhor</b> com os outros;</p>

          <p>3) Você quer parar de tentar <b>resolver tudo sozinho</b>;</p>

          <p>4) Você sente que <b>precisa conversar</b> sobre algo que ninguém entende;</p>

          <p>5) Você está passando por <b>mudanças importantes</b> ou <b>eventos traumáticos</b>;</p>

          <p>6) Você tem dificuldade de lidar com <b>vícios</b> ou <b>compulsões</b>.</p><br />

          <p>Às vezes, os motivos que nos fazem procurar ajuda profissional estão nítidos. Outras vezes, nem tanto, o que pode dificultar nosso entendimento e nos fazer adiar a busca por um psicólogo. Por isso, é preciso ficar atento ao que causa sofrimento.</p>

          <p>A psicoterapia tem diversas funcionalidades, desde o <b>tratamento de transtornos</b> até a busca pelo <b>autoconhecimento</b>, <b>bem-estar</b> e <b>qualidade de vida</b>. Independente de qual seja a sua necessidade, não hesite em buscar ajuda profissional. </p>
        </div>
      </AnimationOnScroll>


      <AnimationOnScroll animateIn="fade-in-bottom" duration={1} animateOnce="true">
        <div className="site__quando-btnMobile">
          <BotaoWhats message={"agende sua consulta"} />
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Quando;
