import React, { useState, useEffect, useRef } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './menuLateral.css';
import { Menu, Icones } from '../../components';


const MenuLateral = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [closingMenu, setClosingMenu] = useState(false);
    const menuRef = useRef(null);

    const handleMenuClose = () => {
        setClosingMenu(true);
        setTimeout(() => {
            setToggleMenu(false);
            setClosingMenu(false);
        }, 200);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="site__menuLat">
            <div className="site__menuLat-btn">
                {toggleMenu
                    ? <RiCloseLine size={27} onClick={handleMenuClose} />
                    : <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
                }
            </div>

            {
                toggleMenu && (
                    <>
                        <div
                            className={`site__menuLat-container ${closingMenu ? 'slide-in-left-menu' : 'slide-in-right'}`}
                        >
                            <div className="site__menuLat-container_links">
                                <Menu />
                            </div>
                            <div className="site__menuLat-container_links-icons">
                                {/* <Icones /> */}
                            </div>
                        </div>
                        <div className={`site__menuLat-overlay ${toggleMenu ? 'show' : ''}`} onClick={handleMenuClose}></div>
                    </>
                )
            }
        </div>
    )
}

export default MenuLateral;