import React from 'react';
import './frase.css';

const Frase = () => {
  return (
    <div className="site__frase section__padding">
      {/* <div className="site__frase-item">
        <div className="site__frase-item_texto">
          <p>“Nenhuma terapia pode alterar os fatos de uma vida, mas pode, isso sim, alterar a narrativa dos fatos 一 e isso talvez seja decisivo.”</p>
        </div>
        <div className="site__frase-item_autor">
          <p>- Contardo Calligaris</p>
        </div>
      </div> */}

      <div className="site__frase-item">
        <div className="site__frase-item_texto">
          <p>“Muitas vezes, é preciso tempo para que as pessoas entendam o que elas mesmas estão dizendo.”</p>
        </div>
        <div className="site__frase-item_autor">
          <p>- Jacques Lacan</p>
        </div>
      </div>
    </div>
  )
}

export default Frase;
