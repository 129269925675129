import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './rodape.css'

const Rodape = () => {
  return (
    <div className="site__rodape">
      <AnimationOnScroll animateIn="slide-in-fwd-bottom" offset={950} duration={0.7} delay={300} animateOnce="true">
        <div className="site__rodape-container">
          <div className="site__rodape-container_content">
            <p>Psicóloga responsável: Ana Lídia Rosa | CRP: 06/198244</p>
            <a href="mailto:guilhermesantosdegodoy@gmail.com">Dev by Guilherme de Godoy</a>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Rodape;
