import React from 'react';
import './roundPic.css';
import { ZoomInEspec } from '../../components';

const RoundPic = ({ image, alt }) => {
  return (
    <div className="round-pic">
      <ZoomInEspec image={image} alt={alt} />
    </div>
  );
};

export default RoundPic;
