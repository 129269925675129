import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './botaoWhats.css';

const BotaoWhats = ({ message }) => {
    const link = 'https://api.whatsapp.com/send/?phone=5511982276060&text&type=phone_number&app_absent=0';

    return (
        <div className="site__whatsBtn">
            <a href={link} target="_blank" rel="noopener noreferrer">
                <button type="button">
                    <span className="site__whatsBtn-icon">
                        <FaWhatsapp />
                    </span>
                    {message}
                </button>
            </a>
        </div>
    )
}

export default BotaoWhats;
