import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './cookieAlert.css';

const CookieAlert = () => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Verifique se o cookie "cookieAlert" já foi definido para evitar exibir o alerta novamente.
    if (!Cookies.get('cookieAlert')) {
      setShowAlert(true);
    }
  }, []);

  const handleAccept = () => {
    // Defina o cookie "cookieAlert" para registrar a aceitação do usuário.
    Cookies.set('cookieAlert', 'accepted', { expires: 365 });
    setShowAlert(false);
  };

  return (
    showAlert && (
      <div className="cookie-alert">
        <p>Este site utiliza cookies para melhorar a sua experiência de navegação. Os cookies são usados apenas para fins técnicos e de análise de tráfego. Ao continuar a usar este site, você concorda com o uso de cookies.</p>
        <button onClick={handleAccept}>Aceitar</button>
      </div>
    )
  );
};

export default CookieAlert;
