import React, { useState, useEffect } from 'react';
import './contato.css';

import emailjs from '@emailjs/browser';
import validator from 'validator';
import InputMask from 'react-input-mask';

function Contato() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [cell, setCell] = useState('');
  const [subject, setSubject] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [cellError, setCellError] = useState('');
  const [subjectError, setSubjectError] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressWidth, setProgressWidth] = useState('0%');

  // Deletar isso depois
  // const env = require('../../env');

  // const emailApiKey = env.EMAIL_API_KEY;
  // const templateApiKey = env.TEMPLATE_EMAIL_API_KEY;
  // const publicKey = env.PUBLIC_KEY;

  // Usar isso aqui depois

  // require('dotenv').config();

  const emailApiKey = process.env.REACT_APP_EMAIL_API_KEY;
  const templateApiKey = process.env.REACT_APP_TEMPLATE_EMAIL_API_KEY;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  function isValidEmail(email) {
    return validator.isEmail(email);
  }

  async function sendEmail(e) {
    e.preventDefault();

    let isValid = true;

    if (name.trim() === '') {
      setNameError('Por favor, digite o seu nome.');
      isValid = false;
    } else {
      setNameError('');
    }

    if (email.trim() === '') {
      setEmailError('Por favor, digite o seu e-mail.');
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Por favor, insira um e-mail válido.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (message.trim() === '') {
      setMessageError('Por favor, digite sua mensagem.');
      isValid = false;
    } else {
      setMessageError('');
    }

    if (cell.trim() === '') {
      setCellError('Por favor, digite o seu celular.');
      isValid = false;
    } else {
      setCellError('');
    }

    if (subject.trim() === '') {
      setSubjectError('Por favor, preencha o campo "Assunto de interesse".');
      isValid = false;
    } else {
      setSubjectError('');
    }

    if (!isValid) {
      return;
    }

    const templateParams = {
      from_name: name,
      email: email,
      message: message,
      cell: cell,
      subject: subject
    };

    try {
      await emailjs.send(emailApiKey, templateApiKey, templateParams, publicKey);

      setName('');
      setEmail('');
      setMessage('');
      setCell('');
      setSubject('');
      setSuccessMessage('E-mail enviado com sucesso!');
      setShowProgressBar(true);

      const animationDuration = 3000;
      setProgressWidth('100%');
      setTimeout(() => {
        setShowProgressBar(false);
      }, animationDuration);

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Erro no EmailJS:', error);

      if (error.includes('The public key is required.')) {
        setErrorMessage('Houve um erro interno no servidor. Por favor, tente novamente mais tarde ou entre em contato por WhatsApp.');
      } else if (error.includes('The service is unavailable.')) {
        setErrorMessage('O servidor de e-mail está inacessível. Tente novamente mais tarde ou entre em contato por WhatsApp.');
      } else {
        setErrorMessage('O envio de e-mail falhou. Por favor, tente novamente mais tarde ou entre em contato por WhatsApp.');
      }

      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }

  }

  useEffect(() => {
    return () => {
      setShowErrorMessage(false);
    };
  }, []);

  return (
    <div className="container section__padding" id="contato">
      <h2 className="subtitle">Fale comigo</h2>
      <h1 className="title">Formulário para contato:</h1>

      <form className="form" onSubmit={sendEmail}>
        <div className="field">
          <label className="label" htmlFor="nome">Nome:</label>
          <input
            id="nome"
            className={`input ${nameError ? 'input-error' : ''}`}
            name="nome"
            type="text"
            placeholder="Digite seu nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
            autoComplete="name"
          />
          {nameError && <p className="error-message">{nameError}</p>}
        </div>

        <div className="field">
          <label className="label" htmlFor="email">E-mail:</label>
          <input
            id="email"
            className={`input ${emailError ? 'input-error' : ''}`}
            name="email"
            type="text"
            placeholder="Digite seu email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoComplete="email"
          />
          {emailError && <p className="error-message">{emailError}</p>}
        </div>

        <div className="field">
          <label className="label" htmlFor="cell">Celular:</label>
          <InputMask
            id="cell"
            className={`input ${cellError ? 'input-error' : ''}`}
            name="cell"
            mask="(99) 99999-9999"
            maskChar="_"
            placeholder="(__) _____-____"
            onChange={(e) => setCell(e.target.value)}
            value={cell}
            autoComplete="tel"
          />
          {cellError && <p className="error-message">{cellError}</p>}
        </div>

        <div className="field">
          <label className="label" htmlFor="subject">Assunto de interesse:</label>
          <input
            id="subject"
            className={`input ${subjectError ? 'input-error' : ''}`}
            name="subject"
            type="text"
            placeholder="Sobre o que você quer conversar?"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            autoComplete="off"
          />
          {subjectError && <p className="error-message">{subjectError}</p>}
        </div>

        <div className="field">
          <label className="label" htmlFor="mensagem">Mensagem:</label>
          <textarea
            id="mensagem"
            className={`textarea ${messageError ? 'input-error' : ''}`}
            name="email"
            placeholder="Digite sua mensagem..."
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            autoComplete="off"
          />
          {messageError && <p className="error-message">{messageError}</p>}
        </div>

        <div className="btn-container">
          <input className="button" type="submit" aria-label="Enviar o formulário" value="Enviar" />
        </div>
      </form>

      <div className="envio-popup">
        {successMessage && (
          <div className="success-popup">
            <p>{successMessage}</p>
          </div>
        )}

        {showProgressBar && (
          <div className="progress-container">
            <div className="progress-bar" style={{ width: progressWidth }}></div>
          </div>
        )}
      </div>

      {showErrorMessage && (
        <div className="error-popup">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Contato;
