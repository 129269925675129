import React from 'react';
import './iconesText.css';
import { gmail, instagram, whatsapp } from './imports';

const IconesText = () => {
    return (
        <div className="site__icones">
            <div className="site__icones-item">
                <a href="mailto:analidiarosa1@gmail.com" className="site__icones-item_linha">
                    <img src={gmail} alt="Ícone gmail" />
                    <p>analidiarosa1@gmail.com</p>
                </a>
            </div>

            <div className="site__icones-item">
                <a href="https://www.instagram.com/analidia_rosa/" className="site__icones-item_linha">
                    <img src={instagram} alt="Ícone instagram" />
                    <p>@analidia_rosa</p>
                </a>
            </div>

            <div className="site__icones-item">
                <a href="https://api.whatsapp.com/send/?phone=5511982276060&text&type=phone_number&app_absent=0" className="site__icones-item_linha">
                    <img src={whatsapp} alt="Ícone whatsapp" />
                    <p>(11) 98227-6060</p>
                </a>
            </div>
        </div>
    )
}

export default IconesText;
