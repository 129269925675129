import React from 'react';
import './infoAdicional.css';

import { IconesText, Logo } from '../../components';

const InfoAdicional = () => {
    return (
        <div className="site__extra section__padding">
            <div className="site__extra-logo">
                <Logo />
            </div>

            <div className="site__extra-horario">
                <h6>Horários de atendimento:</h6>
                <p>Segunda a Sexta | 09h00 às 20h00</p>
                <p>Sábado | 08h00 às 12h00</p>
                <p>Modalidade on-line</p>
            </div>

            <div className="site__extra-contato">
                <h6>Contatos:</h6>
                <IconesText />
            </div>
        </div>
    )
}

export default InfoAdicional;
