import React, { useState, useEffect } from 'react';
import './floatingWhats.css';

import whatsIcon from '../../assets/whatsapp-icon.png';

const FloatingWhats = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const sobreSection = document.getElementById('sobre');
            const sobreRect = sobreSection.getBoundingClientRect();

            const isSobreVisible = sobreRect.top < window.innerHeight && sobreRect.bottom >= 0;
            const isBelowSobre = sobreRect.top < window.innerHeight && sobreRect.bottom < 0;

            setIsVisible(isSobreVisible || isBelowSobre);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openWhatsApp = () => {
        const whatsappLink = 'https://api.whatsapp.com/send/?phone=5511982276060&text&type=phone_number&app_absent=0';
        window.open(whatsappLink, '_blank');
    };

    const buttonClass = isVisible ? 'whatsapp-button' : 'whatsapp-button whatsapp-button-fade';

    return (
        <div className={buttonClass} onClick={openWhatsApp}>
            <img src={whatsIcon} alt="WhatsApp" />
        </div>
    );
};

export default FloatingWhats;